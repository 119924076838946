// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.



import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import 'bootstrap';
import { Menu } from './Menu/Menu';
import { NoticeView } from './Notice/NoticeView';
import '@fortawesome/fontawesome-free/js/all';
import 'animate.css';
import ShareButton from 'share-buttons';

window.onload = () => {
  const menu = new Menu();
  Rails.start();
  Turbolinks.start();
  ActiveStorage.start();
  const holder = document.querySelector('.notice');
  const notice = new NoticeView();
  console.log(ShareButton)
  ShareButton.update()

  // holder.appendChild(notice)
};
window.addEventListener('turbolinks:load',()=>{
  console.log('load')
  ShareButton.update()
})