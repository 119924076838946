export class Menu {
  constructor() {
    this.position = 0;
    this.lastScrollTop = 0;
    this.parent = document.querySelector("#siteHeader");
    this.topMenu = this.parent.querySelector("#topMenu");
    this.hero = this.parent.querySelector("#hero");
    this.subMenu = this.parent.querySelector("#subMenu");
    this.alterMenu = this.parent.querySelector("#alterMenu");
    this.react = {
      fixedSubMenu: this.hero.clientHeight,
    };
    this.eventListeners();
  }

  query(){
    this.parent = document.querySelector("#siteHeader");
    this.topMenu = this.parent.querySelector("#topMenu");
    this.hero = this.parent.querySelector("#hero");
    this.subMenu = this.parent.querySelector("#subMenu");
    this.alterMenu = this.parent.querySelector("#alterMenu");
    this.theTitle = this.parent.querySelector("#theTitle");
  }

  eventListeners() {
    window.addEventListener("scroll", this.onScroll.bind(this));
  }


  onScroll(e) {
    this.position = document.documentElement.scrollTop;
    this.query()
    const delta = 5;
    const nowScrollTop = this.position;

    if (Math.abs(this.lastScrollTop - nowScrollTop) >= delta) {
      if (nowScrollTop > this.lastScrollTop) {
        if (this.position >= this.react.fixedSubMenu) {
          this.subMenu.classList.add("fixed-top");
          this.subMenu.style.top = `${
            this.topMenu.clientHeight
          }px`;
          this.theTitle.classList.remove('desactive')
          this.theTitle.classList.add('active')
        }
      } else {
        if (this.position <= this.react.fixedSubMenu) {
          this.subMenu.classList.remove("fixed-top");
          this.subMenu.style.top = `0px`;
          this.theTitle.classList.remove('active');
          this.theTitle.classList.add('desactive');
        }
      }
      this.lastScrollTop = nowScrollTop;
    }
  }
}
