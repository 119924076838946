import View from "@dragones-tech/view";

export class NoticeView extends View {
    constructor() {
        super();
    }

     template() {
        return ` 
        <div class="alert alert-primary alert-dismissible fade show" role="alert">
            Inscríbete <strong><a class="inscribete" href="/registers/new">aquí</a></strong>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            </div>
        
        `;
    }
    
}

if (!window.customElements.get("notice-view")) {
    window.customElements.define("notice-view", NoticeView);
}
